import React, { useState } from 'react';
import { useTypedSelector } from '@/core/redux/hooks';
import { useFormikContext } from 'formik';
import { BookingOverviewForm } from '@/features/booking/types';
import { getVehicleCategoryData } from '@/features/vehicles/redux/Vehicles.selector';
import styles from '../../assets/scss/components/BookingSummary.module.scss';
import { Button, Column, Heading, Paragraph, Row, Separator } from '@gourban/ui-components';
import { useParams } from 'react-router-dom';
import {
  useRequestResolvedBranchQuery,
  useRequestSingleBranchQuery,
} from '@/features/branches/services/Branches.service';
import { format } from 'date-fns';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import PricingBreakDown from './PricingBreakdown';
import { Trans } from '@lingui/macro';
import { useRequestPaymentSourcesQuery } from '@/features/payment/services/Payment.service';
import LocationGuidelinesModal from '@/features/booking/components/LocationGuidelinesModal';
import { getPersonalPaymentName } from '@/features/booking/utils/getPersonalPaymentName';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import { DateFormats } from '@/core/enums';

const BookingSummary = () => {
  const { values } = useFormikContext<BookingOverviewForm>();
  const { data: resolvedBranch } = useRequestResolvedBranchQuery();
  const { data: personalPayments } = useRequestPaymentSourcesQuery({
    branchId: resolvedBranch!.id,
  });
  const [guidelinesModal, setGuidelinesModal] = useState(false);

  const vehicleCategoryData = useTypedSelector((state) =>
    getVehicleCategoryData(state, values.selectedVehicleCategory),
  );
  const { branchId } = useParams();
  const { data: branchData } = useRequestSingleBranchQuery(branchId!);
  const dateLocale = useDateLocale();

  const bookingStartDate =
    values.bookingDate?.from &&
    format(
      mergeDateTime(values.bookingDate.from, values.bookingTime!.from),
      DateFormats.FULL_DATE_TIME,
      { locale: dateLocale },
    );

  const bookingEndDate =
    (values.bookingDate?.to || values.bookingDate?.from) &&
    format(
      mergeDateTime(values.bookingDate.to ?? values.bookingDate.from!, values.bookingTime!.to),
      DateFormats.FULL_DATE_TIME,
      { locale: dateLocale },
    );

  const isBusinessTrip = values.selectedPaymentType === 'BUSINESS';

  return (
    <div className={styles.summary}>
      <div className={styles.summary__image}>
        <img src={vehicleCategoryData?.appProperties?.image} alt={vehicleCategoryData?.name} />
      </div>

      <Heading marginBottom={24} align="center" size={3} weight="regular">
        {vehicleCategoryData?.name}
      </Heading>

      <Separator gapTop={16} gapBottom={24} backgroundColor="var(--gs-200)" />

      <Paragraph marginBottom={4} size={3} color="var(--gs-500)">
        {isBusinessTrip ? (
          <Trans id="bookings.createSummary.businessTrip">Business trip</Trans>
        ) : (
          <Trans id="bookings.createSummary.personalTrip">Personal trip</Trans>
        )}
      </Paragraph>
      <Paragraph marginBottom={0} size={2} weight="medium">
        {isBusinessTrip ? values.reason : getPersonalPaymentName(personalPayments?.[0])}
      </Paragraph>

      <Separator gapTop={16} gapBottom={24} backgroundColor="var(--gs-200)" />

      <Row alignItems="center" justify="space-between">
        <Column>
          <Paragraph marginBottom={4} size={3} color="var(--gs-500)">
            <Trans id="bookings.createSummary.location">Location</Trans>
          </Paragraph>
          <Paragraph marginBottom={0} size={2} weight="medium">
            {branchData?.name}
          </Paragraph>
        </Column>
        {branchData?.contactInfo?.locationGuidelines && (
          <Column>
            <Button onClick={() => setGuidelinesModal(true)} variation="secondary" size="small">
              <Trans id="bookings.createSummary.locationGuidelines">Location guidelines</Trans>
            </Button>
          </Column>
        )}
      </Row>
      <Separator gapTop={24} gapBottom={24} backgroundColor="var(--gs-200)" />

      <Row gapSm="xs" marginBottom={0}>
        <Column sm={12} md={5}>
          <Paragraph marginBottom={4} size={3} color="var(--gs-500)">
            <Trans id="bookings.createSummary.pickupDate">Pickup date</Trans>
          </Paragraph>
          <Paragraph marginBottom={0} size={2} weight="medium">
            {bookingStartDate}
          </Paragraph>
        </Column>
        <Column sm={12} md={5}>
          <Paragraph marginBottom={4} size={3} color="var(--gs-500)">
            <Trans id="bookings.createSummary.returnDate">Return date</Trans>
          </Paragraph>
          <Paragraph marginBottom={0} weight="medium">
            {bookingEndDate}
          </Paragraph>
        </Column>
      </Row>
      <Separator gapTop={24} gapBottom={24} backgroundColor="var(--gs-200)" />

      <PricingBreakDown vehicleCategoryData={vehicleCategoryData!} />
      {branchData?.contactInfo?.locationGuidelines && (
        <LocationGuidelinesModal
          onClose={() => setGuidelinesModal(false)}
          opened={guidelinesModal}
          locationGuidelines={branchData.contactInfo.locationGuidelines}
        />
      )}
    </div>
  );
};

export default BookingSummary;
