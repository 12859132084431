import { Outlet } from 'react-router-dom';
import { useRequestMeDataQuery } from '@/features/account/services/Account.service';
import { Loader } from '@gourban/ui-components';
import useAccountSetupInit from '@/features/account/hooks/useAccountSetupInit';
import { useRequestBusinessAccountMembershipQuery } from '@/features/businessAccounts/services/BusinessAccount.service';
import { useRequestPackageInformationQuery } from '@/features/payment/services/Payment.service';
import { useRequestResolvedBranchQuery } from '@/features/branches/services/Branches.service';
import ErrorFallback from '@/core/components/ErrorHandlers/ErrorFallback';
import { Trans } from '@lingui/macro';
import { useRequestGuardedSettingsBulkQuery } from '@/core/services/SettingsManagement.service';
import {
  useRequestWhitelabelAppQuery,
  useRequestWhitelabelQuery,
} from '@/core/services/WhiteLabeling.service';

const AppSetupInit = () => {
  const { isLoading: isLoadingMeData, isError: meDataError } = useRequestMeDataQuery();
  const { isLoading: isLoadingBusinessAccountMembership, isError: businessAccountError } =
    useRequestBusinessAccountMembershipQuery();
  const {
    isLoading: isLoadingResolvedBranch,
    data: resolvedBranch,
    isError: branchError,
  } = useRequestResolvedBranchQuery();
  const { isLoading: isLoadingPackageInfo, isError: packageError } =
    useRequestPackageInformationQuery(resolvedBranch?.id!, {
      skip: !resolvedBranch,
    });
  const { isLoading: isLoadingGuardedSettings } = useRequestGuardedSettingsBulkQuery();
  const { isFetching: isFetchingWhiteLabeling } = useRequestWhitelabelQuery();
  const { isFetching: isFetchingWhiteLabelingApp } = useRequestWhitelabelAppQuery();

  useAccountSetupInit();

  // If any of these errors fail, we cant let the user go to the app
  if (meDataError || businessAccountError || branchError || packageError) {
    return (
      <ErrorFallback
        message={
          <Trans id="errorFallback.dataFailedToLoad">Data failed to load. Try again later.</Trans>
        }
      />
    );
  }

  if (
    isLoadingGuardedSettings ||
    isLoadingMeData ||
    isLoadingBusinessAccountMembership ||
    isLoadingResolvedBranch ||
    isLoadingPackageInfo ||
    isFetchingWhiteLabeling ||
    isFetchingWhiteLabelingApp
  ) {
    return <Loader fixed cover />;
  }

  return <Outlet />;
};

export default AppSetupInit;
