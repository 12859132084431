import React, { FC } from 'react';
import { Button, Column, Paragraph, Row } from '@gourban/ui-components';
import { useFormikContext } from 'formik';
import { BookingExtended, BookingOverviewForm } from '@/features/booking/types';
import { format } from 'date-fns';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import { useTypedSelector } from '@/core/redux/hooks';
import { getVehicleCategoryData } from '@/features/vehicles/redux/Vehicles.selector';
import { useNavigate, useParams, Link } from 'react-router-dom';
import styles from '../../assets/scss/components/BookingCompleted.module.scss';
import { t, Trans } from '@lingui/macro';
import Banner from '@/core/components/Banner';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import { DateFormats } from '@/core/enums';

interface BookingCompletedT {
  booking: BookingExtended;
}

const BookingCompleted: FC<BookingCompletedT> = ({ booking }) => {
  const { bookingId } = useParams();
  const { values } = useFormikContext<BookingOverviewForm>();
  const vehicleCategory = useTypedSelector((state) =>
    getVehicleCategoryData(state, values.selectedVehicleCategory),
  );
  const navigate = useNavigate();
  const dateLocale = useDateLocale();

  return (
    <Banner
      icon="checkmark"
      header={`${
        !bookingId
          ? t({ id: 'bookings.create.confirmation', message: 'Booking confirmation for:' })
          : t({ id: 'bookings.update.confirmation', message: 'Booking updated for:' })
      } ${vehicleCategory?.name}`}
      iconColorMode="stroke"
      message={
        <>
          <Column marginBottom={24} sm={12}>
            <Paragraph align="center" color="var(--gs-500)">
              {format(
                mergeDateTime(values.bookingDate?.from!, values.bookingTime?.from),
                DateFormats.FULL_DATE_TIME,
                {
                  locale: dateLocale,
                },
              )}{' '}
              -{' '}
              {format(
                mergeDateTime(
                  values.bookingDate?.to ?? values.bookingDate?.from!,
                  values.bookingTime?.to,
                ),
                DateFormats.FULL_DATE_TIME,
                {
                  locale: dateLocale,
                },
              )}
            </Paragraph>
          </Column>
          {booking.cancellationInformation?.canCancel &&
            booking.cancellationInformation?.freeUntil && (
              <Column sm={12}>
                <Paragraph marginBottom={24} size={3} align="center" color="var(--gs-500)">
                  <Trans id="booking.create.cancelFreeUntil">
                    You can <Link to="/my-trips/upcoming">cancel your booking</Link> free of charge
                    until{' '}
                    {format(
                      new Date(booking.cancellationInformation.freeUntil),
                      DateFormats.FULL_DATE_TIME,
                      { locale: dateLocale },
                    )}
                  </Trans>
                </Paragraph>
              </Column>
            )}
          <Column sm={12}>
            <Row justify="center">
              <Button
                className={styles['booking-completed__button']}
                onClick={() => navigate('/booking')}
              >
                <Trans id="general.ok">Ok</Trans>
              </Button>
            </Row>
          </Column>
        </>
      }
    />
  );
};

export default BookingCompleted;
