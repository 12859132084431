import React, { Dispatch, FC, SetStateAction } from 'react';
import { Button, Column, Row } from '@gourban/ui-components';
import { useFormikContext } from 'formik';
import {
  BookingOverviewForm,
  BookingStep,
  BookingStepKeys,
  BookingSteps,
} from '@/features/booking/types';
import { defaultBookingSteps } from '@/features/booking/components/CreateOrUpdateBooking/CreateOrUpdateBooking';
import { flushSync } from 'react-dom';
import { useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';

export interface OverviewModalFooterT {
  steps: BookingSteps;
  shouldHide: boolean;
  setSteps: Dispatch<SetStateAction<BookingSteps>>;
}

interface ActiveStepExtendedData extends BookingStep {
  key: string;
  index: number;
}

const OverviewModalFooter: FC<OverviewModalFooterT> = ({ steps, setSteps, shouldHide }) => {
  const { bookingId } = useParams();
  const { isValid, validateForm, submitForm, setValues, initialValues } =
    useFormikContext<BookingOverviewForm>();

  if (shouldHide) {
    return null;
  }

  const getCurrentStepData = () => {
    return Object.entries<BookingStep>({ ...steps }).reduce((activeStep, [key, step]) => {
      if (step.status === 'active') {
        activeStep = {
          ...step,
          key,
          index: Object.keys(defaultBookingSteps).findIndex((s) => s === key),
        };
      }

      return activeStep;
    }, {} as ActiveStepExtendedData);
  };

  const handleStepBack = () => {
    const currentActiveStep = getCurrentStepData();

    const nextActiveStepKey = Object.keys(defaultBookingSteps)[
      currentActiveStep.index - 1
    ] as BookingStepKeys;

    if (nextActiveStepKey) {
      flushSync(() => {
        setSteps({
          ...steps,
          [currentActiveStep.key]: { label: currentActiveStep.label, status: 'inactive' },
          [nextActiveStepKey]: {
            label: defaultBookingSteps[nextActiveStepKey].label,
            status: 'active',
          },
        });
      });

      void validateForm();
    }
  };

  const openNextStep = async () => {
    if (steps.summary.status !== 'active') {
      const currentActiveStep = getCurrentStepData();

      const nextActiveStepKey = Object.keys(defaultBookingSteps)[
        currentActiveStep.index + 1
      ] as BookingStepKeys;

      if (nextActiveStepKey) {
        flushSync(() => {
          setSteps({
            ...steps,
            [currentActiveStep.key]: { label: currentActiveStep.label, status: 'completed' },
            [nextActiveStepKey]: {
              label: defaultBookingSteps[nextActiveStepKey].label,
              status: 'active',
            },
          });
        });

        void validateForm();
      }
    } else {
      void submitForm();
    }
  };

  let actionButton;
  if (steps.summary.status === 'active') {
    if (bookingId) {
      actionButton = <Trans id="bookings.update">Update</Trans>;
    } else {
      actionButton = <Trans id="bookings.book">Book</Trans>;
    }
  } else {
    actionButton = <Trans id="bookings.createOrUpdate.nextStep">Next step</Trans>;
  }

  return (
    <Row justify="space-between">
      <Column>
        {steps.date.status === 'active' && (
          <Button
            onClick={() => {
              flushSync(() => {
                void setValues({
                  bookingTime: initialValues.bookingTime,
                });
              });

              void validateForm();
            }}
            variation="secondary"
          >
            <Trans id="bookings.createOrUpdate.reset">Reset</Trans>
          </Button>
        )}

        {steps.date.status !== 'active' && steps.summary.status !== 'active' && (
          <Button onClick={() => handleStepBack()} variation="secondary">
            <Trans id="navigation.goBack">Go back</Trans>
          </Button>
        )}

        {steps.summary.status === 'active' && (
          <Button
            onClick={() => {
              flushSync(() => {
                setSteps(defaultBookingSteps);
              });
            }}
            variation="secondary"
          >
            <Trans id="general.edit">Edit</Trans>
          </Button>
        )}
      </Column>
      <Column>
        <Button onClick={() => openNextStep()} disabled={!isValid}>
          {actionButton}
        </Button>
      </Column>
    </Row>
  );
};

export default OverviewModalFooter;
