import React, { useCallback, useMemo, useRef } from 'react';
import {
  Button,
  Column,
  Paragraph,
  Row,
  Separator,
  DatePickerCalendar,
  TimePicker,
  TimePickerValue,
  Loader,
} from '@gourban/ui-components';
import styles from '../../assets/scss/components/DateSelect.module.scss';
import {
  addDays,
  addHours,
  addMinutes,
  eachDayOfInterval,
  format,
  isBefore,
  isSameHour,
  isSameMinute,
  max,
  startOfDay,
  startOfHour,
  subHours,
} from 'date-fns';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import { useFormikContext } from 'formik';
import { BookingOverviewForm } from '@/features/booking/types';
import { useRequestBookingAvailabilityQuery } from '@/features/booking/services/Booking.service';
import { useParams } from 'react-router-dom';
import { useRequestSingleBranchQuery } from '@/features/branches/services/Branches.service';
import { useRequestUserTermsAndConditionsStatusQuery } from '@/features/account/services/Account.service';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import { DateRange } from '@/core/types';
import { useTypedSelector } from '@/core/redux/hooks';
import { getActiveFilterValues } from '@/features/account/redux/account.selectors';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import { DateFormats } from '@/core/enums';
import { useResponsive } from '@/core/hooks/useResponsive';
import { formatInitialTime } from '@/features/booking/utils/formatInitialTime';
import { addToArrayConditionally } from '@/core/utils/addToArrayConditionally';

const DateSelect = () => {
  const { branchId, bookingId } = useParams();
  const activeFilters = useTypedSelector(getActiveFilterValues);
  const { values, setFieldValue, errors, touched } = useFormikContext<BookingOverviewForm>();
  const { data: bookingSettings } = useRequestBookingSettingQuery();
  const { data: branchData } = useRequestSingleBranchQuery(branchId!, { skip: !branchId });
  const { i18n } = useLingui();
  const { isMobile } = useResponsive();
  const { data: termsAndConditions } = useRequestUserTermsAndConditionsStatusQuery(branchId!, {
    skip: !branchId,
  });
  const { data: bookingAvailabilityDates, isFetching: isFetchingBookingAvailability } =
    useRequestBookingAvailabilityQuery(
      {
        branchId: branchId!,
        maxDateRange: bookingSettings?.value.properties.maxDateRange,
        vehicleAttributes: activeFilters?.vehicleAttributes,
        bookingId,
      },
      {
        skip:
          !branchData || !bookingSettings || !branchId || termsAndConditions?.state !== 'ACCEPTED',
      },
    );
  const dateLocale = useDateLocale();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const unavailableDates = useMemo(() => {
    if (!bookingAvailabilityDates || !branchId) return [];

    return bookingAvailabilityDates
      .filter((booking) => booking.availableState === 'UNAVAILABLE')
      .map((booking) => booking.date);
  }, [bookingAvailabilityDates, branchId]);

  const partiallyAvailableDates = useMemo(() => {
    if (!bookingAvailabilityDates || !branchId) return [];

    return bookingAvailabilityDates
      .filter((booking) => booking.availableState === 'PARTIALLY_AVAILABLE')
      .map((booking) => new Date(booking.date));
  }, [bookingAvailabilityDates, branchId]);

  const checkHourOverlapAfterDateChange = (dateRange: DateRange) => {
    const { bookingTime } = values;
    const [fromHours] = bookingTime?.from?.split(':') ?? '';
    const [toHours] = bookingTime?.to?.split(':') ?? '';

    if (dateRange && !dateRange?.to && fromHours && toHours && +fromHours >= +toHours) {
      void setFieldValue('bookingTime', {
        from: bookingTime!.from,
        to: format(
          new Date(addHours(mergeDateTime(dateRange.from, bookingTime?.from), 1)),
          'HH:mm',
        ),
      });
    }
  };

  const chooseDuration = (hours: number, customFromTime?: string) => {
    const defaultTime = formatInitialTime(format(new Date(), 'HH:mm'), 0);
    const fromDate = values.bookingDate?.from ? new Date(values.bookingDate.from) : new Date();
    const fromTime = customFromTime ?? values.bookingTime?.from ?? defaultTime;

    void setFieldValue('bookingTime', {
      from: fromTime,
      to: format(new Date(addHours(mergeDateTime(fromDate, fromTime), hours)), 'HH:mm'),
    });

    void setFieldValue('bookingDate', {
      from: fromDate,
      to: addHours(mergeDateTime(fromDate, fromTime), hours),
    });
  };

  const durationButtonDisabled = useMemo(() => {
    const fromDate = values.bookingDate?.from ? new Date(values.bookingDate.from) : new Date();
    const fromTime = values.bookingTime?.from;

    const checkIfShouldBeDisabled = (numberOfDays: number, numberOfHours: number) => {
      const range = eachDayOfInterval({
        start: fromDate,
        end: addDays(fromDate, numberOfDays),
      });

      return range.some((day) => {
        const formattedDate = format(day, 'yyyy-MM-dd');
        let isInThePast = false;

        if (fromTime) {
          const [hours] = fromTime.split(':');

          isInThePast = isBefore(addHours(day, numberOfHours + +hours), new Date());
        }

        return unavailableDates.includes(formattedDate) || isInThePast;
      });
    };

    return {
      '1-hour': checkIfShouldBeDisabled(0, 1),
      '3-hour': checkIfShouldBeDisabled(0, 3),
      '5-hour': checkIfShouldBeDisabled(0, 5),
      '1-day': checkIfShouldBeDisabled(1, 24),
    };
  }, [values.bookingDate?.from, unavailableDates, values.bookingTime?.from]);

  const minuteIntervals = useMemo(() => {
    const timeGapInterval = +(bookingSettings?.value?.properties?.timeGap ?? 15);

    return Array.from({ length: 60 / timeGapInterval }, (_, i) => i * timeGapInterval);
  }, [bookingSettings?.value?.properties?.timeGap]);

  const allMinutesInThePast = useCallback(
    (date: Date) => {
      return minuteIntervals.every((minute) =>
        isBefore(addMinutes(startOfHour(date), minute), new Date()),
      );
    },
    [minuteIntervals],
  );

  const shouldBeDisabled = useCallback(
    (value: TimePickerValue, datePeriod: 'from' | 'to', unit: 'hours' | 'minutes') => {
      const { bookingTime, bookingDate } = values;
      const isHour = unit === 'hours';

      if (!bookingTime) {
        return false;
      }

      const latestAvailableStartTime = max([
        ...addToArrayConditionally(
          !!bookingDate?.from,
          mergeDateTime(bookingDate?.from!, bookingTime?.from),
        ),
        new Date(),
      ]);

      const mergedBookingStartTime = addMinutes(
        startOfDay(bookingDate?.from ?? new Date()),
        value!,
      );

      const mergedBookingEndDate = addMinutes(
        startOfDay(bookingDate?.to ?? latestAvailableStartTime),
        value!,
      );

      if (datePeriod === 'from') {
        const isBeforeCurrentTime = isBefore(mergedBookingStartTime, new Date());
        const isSameHourAsCurrent = isHour ? !isSameHour(mergedBookingStartTime, new Date()) : true;
        const allMinutesDisabled = isHour ? allMinutesInThePast(mergedBookingStartTime) : false;

        return (isBeforeCurrentTime && isSameHourAsCurrent) || allMinutesDisabled;
      }

      const isBeforeOrSameAsLatestAvailable =
        isBefore(mergedBookingEndDate, latestAvailableStartTime) ||
        isSameMinute(mergedBookingEndDate, latestAvailableStartTime);

      const isSameHourAsLatestAvailable = isHour
        ? !isSameHour(mergedBookingEndDate, latestAvailableStartTime)
        : true;

      return isBeforeOrSameAsLatestAvailable && isSameHourAsLatestAvailable;
    },

    [values, allMinutesInThePast],
  );

  return (
    <div ref={containerRef} className={styles['date-select']}>
      {isFetchingBookingAvailability && <Loader cover />}
      <Row marginBottom={0} alignItems="center" gapSm="xs" justify="space-between">
        <Column sm={12} md={6}>
          <Paragraph marginBottom={10} size={2}>
            <Trans id="bookings.filters.selectDate">Select date</Trans>
          </Paragraph>
          <Paragraph marginBottom={0} size={3} color="var(--gs-500)">
            {values.bookingDate?.from ? (
              `${format(values.bookingDate.from, DateFormats.SHORT_DATE, {
                locale: dateLocale,
              })} - ${format(
                values.bookingDate.to ?? values.bookingDate.from,
                DateFormats.SHORT_DATE,
                { locale: dateLocale },
              )}`
            ) : (
              <Trans id="bookings.filters.noDateSelected">No date selected</Trans>
            )}
          </Paragraph>
        </Column>
        <Column sm={12} md={6}>
          <Row marginBottom={0} alignItems="center" gapSm="xs" justify="space-between">
            <Column className={styles['date-select__from']} sm={6}>
              <TimePicker
                fieldAttr={{ id: 'from', required: true }}
                fieldProps={{
                  label: t({ id: 'bookings.filters.from', message: 'From' }),
                  size: 'small',
                  iconLeft: 'clock',
                  minuteArrayInterval: bookingSettings?.value?.properties?.timeGap,
                  isHourDisabled: (val) => shouldBeDisabled(val! * 60, 'from', 'hours'),
                  isMinuteDisabled: (val) => {
                    const [hours] = values?.bookingTime?.from?.split(':') ?? '';

                    if (!hours) {
                      return false;
                    }

                    return shouldBeDisabled(+hours * 60 + val!, 'from', 'minutes');
                  },
                  boundingBox: containerRef,
                  onChange: (time) => {
                    const startDate = mergeDateTime(
                      new Date(values.bookingDate?.from ?? new Date()),
                      time,
                    );
                    const endDate = mergeDateTime(
                      new Date(values.bookingDate?.to ?? new Date()),
                      values.bookingTime?.to,
                    );

                    if (isBefore(subHours(endDate, 1), startDate)) {
                      chooseDuration(1, time);
                    }
                  },
                }}
                name="bookingTime.from"
              />
            </Column>
            <Column sm={6}>
              <TimePicker
                fieldAttr={{ id: 'to', required: true }}
                fieldProps={{
                  label: t({ id: 'bookings.filters.to', message: 'Until' }),
                  size: 'small',
                  iconLeft: 'clock',
                  minuteArrayInterval: bookingSettings?.value?.properties?.timeGap,
                  isHourDisabled: (val) => shouldBeDisabled(val! * 60, 'to', 'hours'),
                  isMinuteDisabled: (val) => {
                    const [hours] = values?.bookingTime?.to?.split(':') ?? 'hf';

                    if (!hours) {
                      return false;
                    }

                    return shouldBeDisabled(+hours * 60 + val!, 'to', 'minutes');
                  },
                  boundingBox: containerRef,
                }}
                name="bookingTime.to"
              />
            </Column>
            {errors.bookingTime &&
              (touched.bookingTime as BookingOverviewForm['bookingTime'])?.from &&
              (touched.bookingTime as BookingOverviewForm['bookingTime'])?.to && (
                <Column sm={12}>
                  <Paragraph marginBottom={0} size={3} color="var(--r-300)">
                    {errors.bookingTime}
                  </Paragraph>
                </Column>
              )}
          </Row>
        </Column>
      </Row>

      <div className={styles['date-select__calendar']}>
        <DatePickerCalendar
          fieldProps={{
            isRange: true,
            allowableSelectionRange: {
              minDate: new Date().toString(),
              maxDate: addDays(
                new Date(),
                bookingSettings?.value.properties.maxDateRange ?? 365,
              ).toString(),
            },
            onChange: (dateRange) => {
              checkHourOverlapAfterDateChange(dateRange as DateRange);
            },
            disabledDates: unavailableDates,
            markedDates: partiallyAvailableDates,
            markedDateColor: 'var(--y-400)',
            numberOfMonths: isMobile ? 1 : 2,
          }}
          name="bookingDate"
        />
      </div>

      <Separator backgroundColor="var(--gs-200)" gapBottom={16} gapTop={16} />

      <Paragraph marginBottom={4} size={3}>
        <Trans id="bookings.filters.duration">Duration</Trans>
      </Paragraph>
      <Row marginBottom={0} gapSm="xs">
        <Column>
          <Button
            disabled={durationButtonDisabled['1-hour']}
            onClick={() => chooseDuration(1)}
            size="small"
            variation="secondary"
          >
            {i18n.number(1, { style: 'unit', unit: 'hour', unitDisplay: 'long' })}
          </Button>
        </Column>
        <Column>
          <Button
            disabled={durationButtonDisabled['3-hour']}
            onClick={() => chooseDuration(3)}
            size="small"
            variation="secondary"
          >
            {i18n.number(3, { style: 'unit', unit: 'hour', unitDisplay: 'long' })}
          </Button>
        </Column>
        <Column>
          <Button
            disabled={durationButtonDisabled['5-hour']}
            onClick={() => chooseDuration(5)}
            size="small"
            variation="secondary"
          >
            {i18n.number(5, { style: 'unit', unit: 'hour', unitDisplay: 'long' })}
          </Button>
        </Column>
        <Column>
          <Button
            disabled={durationButtonDisabled['1-day']}
            onClick={() => chooseDuration(24)}
            size="small"
            variation="secondary"
          >
            {i18n.number(1, { style: 'unit', unit: 'day', unitDisplay: 'long' })}
          </Button>
        </Column>
      </Row>
    </div>
  );
};

export default DateSelect;
