import React, { useEffect, useState, FC, useMemo } from 'react';
import { Button, Loader, Paragraph, useMap } from '@gourban/ui-components';
import { Form, FormikProvider, useFormik } from 'formik';
import { trim } from 'lodash';
import DateSelect from '@/features/booking/components/DateSelection/DateSelect';
import CompletionTags from '@/features/booking/components/CompletionTags';
import { BookingExtended, BookingOverviewForm, BookingSteps } from '@/features/booking/types';
import {
  useRequestBookingCreateMutation,
  useRequestBookingUpdateMutation,
} from '@/features/booking/services/Booking.service';
import OverviewModal from '@/features/booking/components/OverviewModal/OverviewModal';
import VehicleCategorySelection from '@/features/booking/components/VehicleCategorySelection/VehicleCategorySelection';
import VehicleCategorySummary from '@/core/features/booking/components/VehicleCategorySummary/VehicleCategorySummary';
import AdditionsSelection from '@/features/booking/components/AdditionsSelection/AdditionsSelection';
import BookingSummary from '@/core/features/booking/components/BookingSummary/BookingSummary';
import BookingCompleted from '@/core/features/booking/components/BookingCompleted/BookingCompleted';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import { isEqual, isSameDay } from 'date-fns';
import { useTypedSelector } from '@/core/redux/hooks';
import {
  getActiveFilterValues,
  getFilteredStationsData,
} from '@/features/account/redux/account.selectors';
import OverviewModalFooter from '@/features/booking/components/OverviewModal/OverviewModalFooter';
import OverviewModalHeader from '@/features/booking/components/OverviewModal/OverviewModalHeader';
import { useRequestSingleBranchQuery } from '@/features/branches/services/Branches.service';
import { useRequestUserTermsAndConditionsStatusQuery } from '@/features/account/services/Account.service';
import { Trans, msg } from '@lingui/macro';
import { CommonValidationTranslations } from '@/core/utils/commonValidationTranslations';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import AccountSelect from '@/features/booking/components/AccountSelection/AccountSelection';
import { useDispatch } from 'react-redux';
import {
  setBookingCreationProcessStarted,
  setClearFiltersRequested,
} from '@/core/features/account/redux/account.reducer';
import { useParams } from 'react-router-dom';

export const defaultBookingSteps: BookingSteps = {
  date: {
    label: msg({ id: 'bookings.createBreadcrumbs.date', message: 'Date' }),
    status: 'active',
  },
  account: {
    label: msg({ id: 'bookings.createBreadcrumbs.account', message: 'Account' }),
    status: 'inactive',
  },
  vehicleCategory: {
    label: msg({ id: 'bookings.createBreadcrumbs.vehicleCategory', message: 'Vehicle category' }),
    status: 'inactive',
  },
  vehicleCategorySummary: { status: 'inactive' },
  extras: {
    label: msg({ id: 'bookings.createBreadcrumbs.extras', message: 'Extras' }),
    status: 'inactive',
  },
  summary: { status: 'inactive' },
  thankYou: { status: 'inactive' },
};

interface CreateOrUpdateBookingT {
  initialValues: BookingOverviewForm;
}

const CreateOrUpdateBooking: FC<CreateOrUpdateBookingT> = ({ initialValues }) => {
  const { branchId, bookingId } = useParams();
  const activeFilters = useTypedSelector(getActiveFilterValues);
  const [bookingSteps, setBookingSteps] = useState<BookingSteps>(defaultBookingSteps);
  const [createBooking, { isLoading: isCreatingBooking }] = useRequestBookingCreateMutation();
  const [updateBooking, { isLoading: isUpdatingBooking }] = useRequestBookingUpdateMutation();
  const isUpserting = isCreatingBooking || isUpdatingBooking;
  const { data: termsAndConditions } = useRequestUserTermsAndConditionsStatusQuery(branchId!);
  const { data: bookingSettings } = useRequestBookingSettingQuery();
  const dispatch = useDispatch();
  const { data: branchData } = useRequestSingleBranchQuery(branchId!, {
    skip: termsAndConditions?.state !== 'ACCEPTED',
  });
  const filteredStations = useTypedSelector(getFilteredStationsData);
  const mapInstance = useMap();
  const [upsertedBookingData, setUpsertedBookingData] = useState<BookingExtended | null>(null);

  // Check if station doesnt have available vehicle categories as user requested in filters
  // This is later used to display a message to the user
  const stationsHasNoFilterMatch = useMemo(() => {
    if (!filteredStations || !branchId || !activeFilters) return false;

    return !filteredStations.find((station) => station.branch.id === +branchId)?.availableCategories
      ?.length;
  }, [branchId, filteredStations, activeFilters]);

  const onSubmitBooking = async (values: BookingOverviewForm) => {
    const {
      bookingDate,
      bookingTime,
      selectedVehicleCategory: vehicleCategoryId,
      selectedAdditions: additions,
      selectedPaymentMethod,
      selectedPaymentType,
      reason,
    } = values;

    const data = {
      branchId: branchId!,
      vehicleCategoryId: vehicleCategoryId!,
      additions,
      reason: trim(reason),
      rentalType: selectedPaymentType!,
      userGroupCode: selectedPaymentType === 'BUSINESS' ? selectedPaymentMethod! : null,
      startTime: new Date(mergeDateTime(bookingDate!.from!, bookingTime?.from)).toISOString(),
      endTime: new Date(
        mergeDateTime(bookingDate?.to ?? bookingDate!.from!, bookingTime?.to),
      ).toISOString(),
    };

    if (bookingId) {
      await updateBooking({ id: bookingId, ...data })
        .unwrap()
        .then(setUpsertedBookingData);
    } else {
      await createBooking(data).unwrap().then(setUpsertedBookingData);
    }

    setBookingSteps((prev) => ({
      ...prev,
      summary: { status: 'completed' },
      thankYou: { status: 'active' },
    }));
  };

  const validate = (values: BookingOverviewForm) => {
    const errors: Record<string, string> = {};

    if (!values.bookingDate?.from && !values.bookingDate?.to) {
      errors.bookingDate = CommonValidationTranslations.requiredField();
    }

    if (
      !values.bookingTime?.from ||
      !values.bookingTime?.to ||
      values?.bookingTime?.from?.includes('--') ||
      values?.bookingTime?.to?.includes('--')
    ) {
      errors.bookingTime = CommonValidationTranslations.requiredField();
    }

    if (bookingSteps.vehicleCategory.status === 'active' && !values.selectedVehicleCategory) {
      errors.selectedVehicleCategory = CommonValidationTranslations.requiredField();
    }

    if (
      bookingSteps.vehicleCategorySummary.status === 'active' &&
      !values.rentalRequirementsFulfilled
    ) {
      errors.rentalRequirementsFulfilled = CommonValidationTranslations.requiredField();
    }

    if (
      bookingSteps.account.status === 'active' &&
      !values.selectedPaymentMethod &&
      !values.selectedPaymentType
    ) {
      errors.selectedPaymentMethod = CommonValidationTranslations.requiredField();
    }

    if (
      bookingSteps.account.status === 'active' &&
      values.selectedPaymentType === 'BUSINESS' &&
      bookingSettings?.value.reason?.businessUser === 'required' &&
      !trim(values.reason)
    ) {
      errors.reason = CommonValidationTranslations.requiredField();
    }

    return errors;
  };

  const bookingOverviewForm = useFormik<BookingOverviewForm>({
    initialValues,
    validate,
    validateOnMount: true,
    onSubmit: onSubmitBooking,
  });

  // We need to reset the form everytime ID changes because user needs to go through the process again
  useEffect(() => {
    setBookingSteps(defaultBookingSteps);
    bookingOverviewForm.resetForm();
    // flushSync doesn't work inside useEffect, have to wrap with setTimeout
    setTimeout(() => {
      bookingOverviewForm.validateForm();
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  // When ever user changes start date, we need to reset values of some form elements on other fields
  useEffect(() => {
    if (
      bookingOverviewForm.values.bookingDate?.from &&
      bookingOverviewForm.initialValues.bookingDate?.from &&
      !isEqual(
        bookingOverviewForm.values.bookingDate?.from,
        bookingOverviewForm.initialValues.bookingDate?.from,
      )
    ) {
      // Only do this if activeBooking is not present, because otherwise it would trigger clear of values that exist and should be removed
      bookingOverviewForm.setValues({
        bookingTime: bookingOverviewForm.values.bookingTime,
        bookingDate: bookingOverviewForm.values.bookingDate,
        selectedPaymentType: bookingOverviewForm.initialValues.selectedPaymentType,
        selectedPaymentMethod: bookingOverviewForm.initialValues.selectedPaymentMethod,
        reason: bookingOverviewForm.initialValues?.reason,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingOverviewForm.values.bookingDate?.from]);

  useEffect(() => {
    const dateFromChanged = !isSameDay(
      bookingOverviewForm.values.bookingDate?.from!,
      bookingOverviewForm.initialValues.bookingDate?.from!,
    );

    const dateToChanged = !isSameDay(
      bookingOverviewForm.values.bookingDate?.to!,
      bookingOverviewForm.initialValues.bookingDate?.to!,
    );

    const timeFromChanged =
      bookingOverviewForm.values.bookingTime?.from !==
      bookingOverviewForm.initialValues.bookingTime?.from;

    const timeToChanged =
      bookingOverviewForm.values.bookingTime?.to !==
      bookingOverviewForm.initialValues.bookingTime?.to;

    const shouldStartBookingCreationProcess = bookingId
      ? dateFromChanged || dateToChanged || timeFromChanged || timeToChanged
      : Boolean(bookingOverviewForm.values.bookingDate?.from);

    dispatch(setBookingCreationProcessStarted(shouldStartBookingCreationProcess));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingOverviewForm.values.bookingDate, bookingOverviewForm.values.bookingTime]);

  useEffect(() => {
    const map = mapInstance.current;

    if (!map) {
      return undefined;
    }

    map.getMap().dragPan.disable();
    map.getMap().scrollZoom.disable();
    map.getMap().keyboard.disable();
    map.getMap().doubleClickZoom.disable();

    return () => {
      map.getMap().dragPan.enable();
      map.getMap().scrollZoom.enable();
      map.getMap().keyboard.enable();
      map.getMap().doubleClickZoom.enable();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLastStep = bookingSteps.summary.status === 'active';

  return (
    <FormikProvider value={bookingOverviewForm}>
      <OverviewModal
        displayFallback={!branchData}
        modalHeader={
          <OverviewModalHeader
            shouldHide={!branchData || bookingSteps.thankYou.status === 'active'}
            customHeading={
              isLastStep && <Trans id="bookings.createOrUpdate.overview">Booking overview</Trans>
            }
            steps={Object.values(bookingSteps)}
          />
        }
        modalFooter={
          bookingSteps.thankYou.status !== 'active' &&
          !stationsHasNoFilterMatch && (
            <OverviewModalFooter
              setSteps={setBookingSteps}
              steps={bookingSteps}
              shouldHide={!branchData}
            />
          )
        }
      >
        <Form>
          {stationsHasNoFilterMatch && (
            <>
              <CompletionTags bookingSteps={bookingSteps} />
              <Paragraph weight="medium">
                <Trans id="bookings.createOrUpdate.noFilterMatch">
                  We are sorry, but we couldn't find any vehicles available for this station that
                  match your search criteria.
                </Trans>
              </Paragraph>
              <Button
                onClick={() => {
                  dispatch(setClearFiltersRequested(true));
                  bookingOverviewForm.setFieldValue('bookingDate', undefined);
                }}
                size="small"
              >
                <Trans id="bookings.createOrUpdate.clearFilters">Clear filters</Trans>
              </Button>
            </>
          )}

          {isUpserting && <Loader cover />}

          {!isUpserting && !stationsHasNoFilterMatch && (
            <>
              {!isLastStep && bookingSteps.thankYou.status !== 'active' && (
                <CompletionTags bookingSteps={bookingSteps} />
              )}
              {bookingSteps.date.status === 'active' && <DateSelect />}
              {bookingSteps.account.status === 'active' && <AccountSelect />}
              {bookingSteps.vehicleCategory.status === 'active' && <VehicleCategorySelection />}
              {bookingSteps.vehicleCategorySummary.status === 'active' && (
                <VehicleCategorySummary />
              )}
              {bookingSteps.extras.status === 'active' && (
                <AdditionsSelection setSteps={setBookingSteps} />
              )}
              {bookingSteps.summary.status === 'active' && <BookingSummary />}
              {bookingSteps.thankYou.status === 'active' && (
                <BookingCompleted booking={upsertedBookingData!} />
              )}
            </>
          )}
        </Form>
      </OverviewModal>
    </FormikProvider>
  );
};

export default CreateOrUpdateBooking;
