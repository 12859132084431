import React, { ReactNode, useMemo } from 'react';
import { Paragraph, Separator, Svg, Tags, IconName, Row, Column } from '@gourban/ui-components';
import styles from '../assets/scss/components/CompletionTags.module.scss';
import { useFormikContext } from 'formik';
import { addToArrayConditionally } from '@/core/utils/addToArrayConditionally';
import { BookingOverviewForm, BookingSteps } from '@/features/booking/types';
import { format } from 'date-fns';
import { useTypedSelector } from '@/core/redux/hooks';
import { getVehicleCategoryData } from '@/features/vehicles/redux/Vehicles.selector';
import { useParams } from 'react-router-dom';
import { useRequestSingleBranchQuery } from '@/features/branches/services/Branches.service';
import { DateFormats } from '@/core/enums';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import { t } from '@lingui/macro';

interface BookingCompletionTagsT {
  bookingSteps: BookingSteps;
}

interface SingleTagT {
  icon: IconName;
  content: ReactNode;
}

const SingleTag: React.FC<SingleTagT> = ({ icon, content }) => {
  return (
    <Column>
      <Tags
        style="squared"
        size="tiny"
        weight="regular"
        bgColor="white"
        borderColor="var(--gs-200)"
        content={
          <div className={styles['completion-tags__tag']}>
            <Svg icon={icon} width={14} height={14} iconColor="var(--gs-400)" />
            {content}
          </div>
        }
      />
    </Column>
  );
};

const CompletionTags: React.FC<BookingCompletionTagsT> = ({ bookingSteps }) => {
  const { branchId } = useParams();
  const { values } = useFormikContext<BookingOverviewForm>();
  const vehicleCategory = useTypedSelector((state) =>
    getVehicleCategoryData(state, values.selectedVehicleCategory),
  );
  const { data: branchData } = useRequestSingleBranchQuery(branchId!);
  const dateLocale = useDateLocale();

  const tags = useMemo(() => {
    return [
      ...addToArrayConditionally(!!branchData?.contactInfo?.address?.street, {
        icon: 'location',
        id: 'location',
        content: branchData?.contactInfo?.address?.street,
      }),
      ...addToArrayConditionally(bookingSteps.date.status === 'completed', {
        icon: 'calendar',
        id: 'date',
        content: values.bookingDate?.from
          ? `${format(values.bookingDate.from, DateFormats.SHORT_DATE, { locale: dateLocale })} / ${
              values.bookingTime?.from
            }`
          : undefined,
      }),
      ...addToArrayConditionally(bookingSteps.date.status === 'completed', {
        icon: 'calendar',
        id: 'time',
        content:
          values.bookingDate?.to || values.bookingDate?.from
            ? `${format(
                values.bookingDate?.to ?? values.bookingDate?.from!,
                DateFormats.SHORT_DATE,
                { locale: dateLocale },
              )} / ${values.bookingTime?.to}`
            : undefined,
      }),
      ...addToArrayConditionally(bookingSteps.account.status === 'completed', {
        icon: 'profile',
        id: 'account',
        content:
          values.selectedPaymentType === 'PERSONAL'
            ? t({
                id: `bookings.createOrUpdate.personalTrip`,
                message: 'Personal',
              })
            : t({
                id: `bookings.createOrUpdate.businessTrip`,
                message: 'Business',
              }),
      }),
      ...addToArrayConditionally(bookingSteps.vehicleCategorySummary.status === 'completed', {
        icon: 'car',
        id: 'vehicles',
        content: vehicleCategory?.name,
      }),
    ] as (SingleTagT & { id: 'string' })[];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, bookingSteps, branchData, vehicleCategory]);

  return (
    <div className={styles['completion-tags']}>
      <Paragraph size={2} weight="medium">
        {branchData?.contactInfo?.publicName ?? branchData?.name}
      </Paragraph>
      <Row gapSm="xs">
        {tags.map((tag) => (
          <SingleTag key={tag.id} icon={tag.icon} content={tag.content} />
        ))}
      </Row>

      <Separator backgroundColor="var(--gs-200)" gapBottom={32} gapTop={24} />
    </div>
  );
};

export default CompletionTags;
