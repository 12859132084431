import React, { PropsWithChildren, ReactNode } from 'react';
import { Modal, Paragraph } from '@gourban/ui-components';
import { useNavigate } from 'react-router-dom';
import styles from '../../assets/scss/components/OverviewModal.module.scss';
import { Trans } from '@lingui/macro';

interface BookingOverviewModalT {
  modalFooter?: ReactNode;
  modalHeader?: ReactNode;
  displayFallback: boolean;
}

const OverviewModal: React.FC<PropsWithChildren<BookingOverviewModalT>> = ({
  modalFooter,
  modalHeader,
  children,
  displayFallback,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      modalFooter={modalFooter}
      overlayClassName={styles['overview-modal__overlay']}
      modalHeader={modalHeader}
      contentClassName={
        displayFallback ? styles['overview-modal__no-results'] : styles['overview-modal__content']
      }
      className={styles['overview-modal']}
      innerModalClassName={styles['overview-modal__inner']}
      height="expand"
      align="right"
      variation="small"
      onClose={() => navigate({ pathname: '..', search: window.location.search })}
      opened
    >
      {displayFallback ? (
        <Paragraph align="center" size={1} color="var(--gs-600)">
          <Trans id="overviewModal.stationNotAvailable">Location not available.</Trans>
        </Paragraph>
      ) : (
        children
      )}
    </Modal>
  );
};

export default OverviewModal;
