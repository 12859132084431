import React, { FC, useMemo } from 'react';
import { MyTripT } from './MyTrip';
import StatusLayout from '@/features/myTrips/components/Trips/StatusLayout';
import { Svg } from '@gourban/ui-components';
import { parseISO, getYear, format } from 'date-fns';
import styles from '@/features/myTrips/assets/scss/components/Trips/StatusLayout.module.scss';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import type { Locale } from 'date-fns';
import { useTypedSelector } from '@/core/redux/hooks';
import { getVehicleCategoryAdditions } from '@/features/vehicles/redux/Vehicles.selector';

export const formatDateAndTime = (time: string, locale?: Locale): string => {
  const date = parseISO(time);
  const currentYear = getYear(new Date());
  const yearOfDate = getYear(date);

  return currentYear === yearOfDate
    ? format(date, 'dd LLL HH:mm', { locale })
    : format(date, 'PPP', { locale });
};

const MyTripStatus: FC<Pick<MyTripT, 'trip'>> = ({ trip }) => {
  const city = trip.branch.contactInfo?.address.city;
  const hasStartAndEndTime = trip.startTime && trip.endTime;
  const isCanceled = trip.state === 'CANCELED';
  const locale = useDateLocale();
  const businessTrip = trip.rentalType === 'BUSINESS';
  const additions = useTypedSelector(getVehicleCategoryAdditions);

  const additionsString = useMemo(() => {
    if (!additions || !trip?.additions) return null;

    return trip.additions
      .map((addition) => additions.find((a) => a.code === addition)?.name)
      .filter(Boolean)
      .join(', ');
  }, [additions, trip]);

  const rentalType = businessTrip ? (
    <Trans id="myTrips.status.rentalType.business">Business</Trans>
  ) : (
    <Trans id="myTrips.status.rentalType.personal">Personal</Trans>
  );

  return (
    <>
      <StatusLayout
        icon="user"
        title={<Trans id="myTrips.status.title">Account</Trans>}
        content={rentalType}
      />
      {city && (
        <StatusLayout
          icon="location"
          title={<Trans id="myTrips.status.location">Location</Trans>}
          content={city}
        />
      )}
      {additionsString && (
        <StatusLayout
          icon="plugins"
          title={<Trans id="myTrips.status.extras">Extras</Trans>}
          content={additionsString}
        />
      )}
      {hasStartAndEndTime && (
        <StatusLayout
          icon="calendar"
          title={<Trans id="myTrips.status.startAndEndTime">Pick-up and return date</Trans>}
          content={
            <>
              {formatDateAndTime(trip.startTime, locale)}
              <Svg
                icon="arrowRight"
                iconColor="var(--gs-300)"
                height={12}
                width={12}
                className={styles['status__content-icon']}
              />
              {formatDateAndTime(trip.endTime, locale)}
            </>
          }
        />
      )}
      {isCanceled && (
        <StatusLayout
          icon="restricted"
          title={<Trans id="myTrips.status">Status</Trans>}
          content={<Trans id="myTrips.status.canceled">Canceled</Trans>}
        />
      )}
      {trip.rental?.distance != null && (
        <StatusLayout
          icon="trips"
          title={<Trans id="myTrips.distance.title">Distance</Trans>}
          content={i18n.number(trip.rental.distance, {
            style: 'unit',
            unit: 'kilometer',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            unitDisplay: 'short',
          })}
        />
      )}
      {businessTrip && (
        <StatusLayout
          icon="agenda"
          title={<Trans id="myTrips.businessTripReason.title">Reason for business trip</Trans>}
          content={trip.reason}
        />
      )}
    </>
  );
};

export default MyTripStatus;
