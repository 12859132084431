import React from 'react';
import TermsAndConditions from '@/features/booking/components/CreateOrUpdateBooking/TermsAndConditions';
import CreateOrUpdateBooking from '@/features/booking/components/CreateOrUpdateBooking/CreateOrUpdateBooking';
import { useParams } from 'react-router-dom';
import { useRequestUserTermsAndConditionsStatusQuery } from '@/features/account/services/Account.service';
import { Loader } from '@gourban/ui-components';
import { useBookingPreparation } from '@/features/booking/hooks/useBookingPreparation';
import OverviewModal from '@/core/features/booking/components/OverviewModal/OverviewModal';

const BookingOverview = () => {
  const { branchId, bookingId } = useParams();
  const {
    data: termsAndConditions,
    isFetching,
    isError: errorFetchingTermsAndConditions,
  } = useRequestUserTermsAndConditionsStatusQuery(branchId!);
  const { isFetchingBookingData, bookingInitialValues, isError } = useBookingPreparation(
    branchId,
    bookingId,
    termsAndConditions?.state,
  );

  if (isError || errorFetchingTermsAndConditions) {
    return <OverviewModal modalHeader={<span />} displayFallback />;
  }

  if (isFetching || isFetchingBookingData) {
    return <Loader cover />;
  }

  if (!termsAndConditions) {
    return null;
  }

  return termsAndConditions.state === 'NOT_ACCEPTED' ? (
    <TermsAndConditions />
  ) : (
    <CreateOrUpdateBooking initialValues={bookingInitialValues} />
  );
};

export default BookingOverview;
