import React, { FC } from 'react';
import {
  useRequestUserTermsAndConditionsStatusQuery,
  useRequestUserAcceptedTermsAndConditionsMutation,
} from '@/features/account/services/Account.service';
import { useParams } from 'react-router-dom';
import { Button, Column, Paragraph, Row } from '@gourban/ui-components';
import AcceptTermsAndConditions from '@/core/features/auth/components/AcceptTermsAndConditions';
import { Form, FormikProvider, useFormik } from 'formik';
import { termsAndConditionValidationSchema } from '@/core/utils/validations/termsAndConditions.schema';
import OverviewModal from '@/features/booking/components/OverviewModal/OverviewModal';
import OverviewModalHeader from '@/features/booking/components/OverviewModal/OverviewModalHeader';
import { useGoBack } from '@/core/hooks/useGoBack';
import { Trans } from '@lingui/macro';
import DisclaimerText from '@/features/auth/components/DisclaimerText';

const TermsAndConditions: FC = () => {
  const { branchId } = useParams();
  const { data: termsAndConditions } = useRequestUserTermsAndConditionsStatusQuery(branchId!);
  const [acceptTermsAndConditions] = useRequestUserAcceptedTermsAndConditionsMutation();
  const goBack = useGoBack();

  const onTermsAccepted = () => {
    return acceptTermsAndConditions(branchId!).unwrap();
  };

  const termsAndConditionsForm = useFormik({
    initialValues: { terms: false, privacy: false },
    validationSchema: termsAndConditionValidationSchema,
    onSubmit: onTermsAccepted,
  });

  return (
    <OverviewModal
      displayFallback={false}
      modalHeader={
        <OverviewModalHeader
          steps={[]}
          customHeading={<Trans id="bookings.termsAndConditions.title">Agreement</Trans>}
          shouldHide={false}
        />
      }
      modalFooter={
        <Row justify="space-between">
          <Column>
            <Button onClick={goBack} variation="secondary">
              <Trans id="bookings.termsAndConditions.refuse">Refuse</Trans>
            </Button>
          </Column>
          <Column>
            <Button
              loading={termsAndConditionsForm.isSubmitting}
              onClick={termsAndConditionsForm.submitForm}
              disabled={!(termsAndConditionsForm.isValid && termsAndConditionsForm.dirty)}
            >
              <Trans id="bookings.termsAndConditions.accept">Accept</Trans>
            </Button>
          </Column>
        </Row>
      }
    >
      <FormikProvider value={termsAndConditionsForm}>
        <Form>
          <Paragraph color="var(--gs-500)" size={3} marginBottom={24}>
            <Trans id="bookings.termsAndConditions.description">
              Before continuing, please accept the following agreements:
            </Trans>
          </Paragraph>

          <AcceptTermsAndConditions
            termsAndConditionsUrl={
              termsAndConditions!.latestTermsAndConditions.termsAndConditionsUrl
            }
            termsAndConditionsText={
              termsAndConditions!.latestTermsAndConditions.termsAndConditionsText
            }
            privacyGuidelinesUrl={termsAndConditions!.latestTermsAndConditions.privacyGuidelinesUrl}
            privacyGuidelinesText={
              termsAndConditions!.latestTermsAndConditions.privacyGuidelinesText
            }
          />

          <DisclaimerText />
        </Form>
      </FormikProvider>
    </OverviewModal>
  );
};

export default TermsAndConditions;
